import React, { useState } from 'react';
import { MdNotificationsActive } from 'react-icons/md'; // Importing the chosen icon
import styled from 'styled-components';
import useTestAlerts from './api/TestAlertActions';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #6200ea;
  color: yellow;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3700b3;
  }

  svg {
    margin-right: 8px;
  }
`;

const AdminTestAlerts: React.FC = () => {
  const defaultChatRPGId = 'CRPG1COWSEP';
  const [chatRPGId, setChatRPGId] = useState<string>(defaultChatRPGId);
  const { sendFakeTwitchSubscription, sendFakeTwitchBits, sendFakeYouTubeSuperChat, sendFakeTikTokGift, sendFakeYouTubeSponsor, sendFakeTikTokSubscriber, sendFakeTwitchChannelPoints, sendFakeTwitchCommercial, sendFakeTwitchFollow, unlinkStreamerKick } = useTestAlerts();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChatRPGId(e.target.value);
  };

  const handleTestTwitchSubscription = async () => {
    const result = await sendFakeTwitchSubscription(chatRPGId);
    alert(result.message);
  };

  const handleTestTwitchCommercial = async () => {
    const result = await sendFakeTwitchCommercial(chatRPGId);
    alert(result.message);
  };

  const handleTestBitsAlert = async () => {
    const result = await sendFakeTwitchBits(chatRPGId);
    alert(result.message);
  };

  const handleTestTwitchPoints = async () => {
    const result = await sendFakeTwitchChannelPoints(chatRPGId);
    alert(result.message);
  };

  const handleTestYouTubeSuperChat = async () => {
    const result = await sendFakeYouTubeSuperChat(chatRPGId);
    alert(result.message);
  };

  const handleTestTikTokGift = async () => {
    const result = await sendFakeTikTokGift(chatRPGId);
    alert(result.message);
  };

  const handleTestYouTubeSponsor = async () => {
    const result = await sendFakeYouTubeSponsor(chatRPGId);
    alert(result.message);
  };

  const handleTestTiktokSubscription = async () => {
    const result = await sendFakeTikTokSubscriber(chatRPGId);
    alert(result.message);
  };

  const handleTestTwitchFollow = async () => {
    const result = await sendFakeTwitchFollow(chatRPGId);
    alert(result.message);
  };

  const handleUnlinkKick = async () => {
    const result = await unlinkStreamerKick(chatRPGId);
    alert(result.message);
  };


  return (
    <div>
      <h3>Admin Test Alerts</h3>
      <div>
        <input
          type="text"
          value={chatRPGId}
          onChange={handleInputChange}
          placeholder="Enter ChatRPG ID"
        />
      </div>
      <StyledButton onClick={handleTestTwitchSubscription}>
        <MdNotificationsActive size={24} />
        Test Twitch Subscription on Cowsep
      </StyledButton>
      <StyledButton onClick={handleTestTwitchCommercial}>
        <MdNotificationsActive size={24} />
        Test Twitch Commercial on Cowsep
      </StyledButton>
      <StyledButton onClick={handleTestBitsAlert}>
        <MdNotificationsActive size={24} />
        Test Twitch Bits on Cowsep
      </StyledButton>
      <StyledButton onClick={handleTestTwitchPoints}>
        <MdNotificationsActive size={24} />
        Test Twitch Channel Points on Cowsep
      </StyledButton>
      <StyledButton onClick={handleTestYouTubeSuperChat}>
        <MdNotificationsActive size={24} />
        Test YouTube Super Chat
      </StyledButton>
      <StyledButton onClick={handleTestTikTokGift}>
        <MdNotificationsActive size={24} />
        Test TikTok Gift
      </StyledButton>
      <StyledButton onClick={handleTestYouTubeSponsor}>
        <MdNotificationsActive size={24} />
        Test YouTube Sponsor
      </StyledButton>
      <StyledButton onClick={handleTestTiktokSubscription}>
        <MdNotificationsActive size={24} />
        Test Tiktok Subscription
      </StyledButton>
      <StyledButton onClick={handleTestTwitchFollow}>
        <MdNotificationsActive size={24} />
        Test Twitch Follow
      </StyledButton>
      <StyledButton onClick={handleUnlinkKick}>
        <MdNotificationsActive size={24} />
        UNLINK KICK!!!
      </StyledButton>
    </div>
  );
};

export default AdminTestAlerts;
