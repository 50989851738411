import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SERVER_URL } from '../../../../../../env';
import { useToken } from '../../../../../contexts/TokenContext';
import { useUserProfile } from '../../../../../contexts/UserProfileContext';
import SaveButton from '../../../../../ui/button/SaveButton';

import './OptionalOptions.css'; // Import CSS file
import './OptionalOptionsSwitch.css';

interface SwitchStates {
    disablePlayer: boolean;
    disableEmbeds: boolean;
    disableSidebar: boolean;
    // Add more options here as needed
}

const readableNames: { [key in keyof SwitchStates]: string } = {
    disablePlayer: 'Disable Player in Chat',
    disableEmbeds: 'Disable Embed Selection under player',
    disableSidebar: 'Disable Sidebar',
    // Add more mappings here as needed
};

const OptionSwitch = () => {
    const dispatch = useDispatch();
    const { token } = useToken();
    const { profile, setProfile } = useUserProfile(); // Use the user profile context
    const [isChanged, setIsChanged] = useState(false);

    // Initialize switchStates based on the user profile settings
    const initialSwitchStates: SwitchStates = {
        disableEmbeds: profile?.settings?.disablePlayer ?? false,
        disablePlayer: profile?.settings?.disablePlayer ?? false,
        disableSidebar: profile?.settings?.disableSidebar ?? false,
        // Add more options here as needed
    };

    const [switchStates, setSwitchStates] = useState(initialSwitchStates);

    const compareSwitchStatesWithProfile = () => {
        // Ensure the function always returns a boolean by using !! to convert the value to boolean
        return !!profile?.settings && Object.keys(switchStates).some(key =>
            switchStates[key as keyof SwitchStates] !== profile.settings[key as keyof SwitchStates]
        );
    };

    useEffect(() => {
        setIsChanged(compareSwitchStatesWithProfile());
    }, [switchStates, profile]);

    const toggleSwitch = (option: keyof SwitchStates) => {
        setSwitchStates(prevStates => ({
            ...prevStates,
            [option]: !prevStates[option]
        }));
    };

    useEffect(() => {
        setSwitchStates({
            disablePlayer: profile?.settings?.disablePlayer || false,
            disableEmbeds: profile?.settings?.disableEmbeds || false,
            disableSidebar: profile?.settings?.disableSidebar || false,
            // Add more options here as needed
        });
    }, [profile]);

    const handleSave = async () => {
        dispatch({ type: 'START_LOADING' });
        try {
            const response = await fetch(`${SERVER_URL}/profile/options/settings`, { // Update the URL to your user settings endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token here
                },
                body: JSON.stringify(switchStates), // Send the switchStates as the request body
                credentials: 'include', // if you're using sessions
            });

            if (response.ok) {
                const updatedUserProfile = await response.json();
                console.log('User settings saved successfully');
                setIsChanged(false);
                setProfile(updatedUserProfile); // Update the user profile in the context
            } else {
                console.error('Failed to save user settings');
            }
        } catch (error) {
            console.error('Error:', error);
        }
        dispatch({ type: 'STOP_LOADING' });
    };


    return (
        <>
            <div className="add-platform-container">
                <SaveButton onSave={handleSave} isDisabled={!isChanged} />
            </div>
            <div className="optionalOptions-container">
                {Object.entries(switchStates).map(([option, isEnabled]) => (
                    <div key={option} className="optionalOptions-option-switch">
                        <span className="optionalOptions-option-name">
                            {readableNames[option as keyof SwitchStates]}
                        </span>
                        <label className="optionalOptions-switch">
                            <input type="checkbox" checked={isEnabled} onChange={() => toggleSwitch(option as keyof SwitchStates)} />
                            <span className="optionalOptions-slider optionalOptions-round"></span>
                        </label>
                    </div>
                ))}
            </div>
        </>
    );
};

export default OptionSwitch;
