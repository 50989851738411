import { createRoot } from "react-dom/client"
import ReactGA from "react-ga"
import { Provider } from "react-redux"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import store from "./store"

// import "./index.css"

ReactGA.initialize("G-E0ZRPB46TT")

const root = document.getElementById("root")
if (!root) throw new Error("No root element found.")

createRoot(root).render(
  // <StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
