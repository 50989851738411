import React, { useState, useEffect } from 'react';
import SaveButton from '../../../../../ui/button/SaveButton';
import { useToken } from '../../../../../contexts/TokenContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { UserStreamerProfile, setUserStreamerProfile } from '../../../../../../store/reducers/userStreamerProfileReducer';
import './StreamerSettings.css';
import { saveStreamerSettings } from './api/saveStreamerSettings';
import InputWidth from '../../../../../ui/compound/inputwidth/InputWidth';
import Checkbox from '../../../../../ui/checkbox/Checkbox';

const StreamerSettings = () => {
    const dispatch = useDispatch();
    const { token } = useToken();
    const userStreamerProfile = useSelector((state: RootState) => state.userStreamerProfile) as UserStreamerProfile;
    const [donationLinkInputField, setDonationLinkInputField] = useState(userStreamerProfile?.settings?.donateButton || '');
    const [placeholderLinkInputField, setPlaceholderLinkInputField] = useState(userStreamerProfile?.settings?.placeholder || '');
    const [alertsButton, setAlertsButton] = useState(userStreamerProfile?.settings?.alertsButton || false);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        const anyChanges = userStreamerProfile?.settings?.donateButton !== donationLinkInputField ||
            userStreamerProfile?.settings?.placeholder !== placeholderLinkInputField ||
            userStreamerProfile?.settings?.alertsButton !== alertsButton;
        setIsChanged(anyChanges);
    }, [donationLinkInputField, placeholderLinkInputField, alertsButton, userStreamerProfile]);

    const handleDonationLinkChange = (e: any) => {
        console.log('Donation link changed:', e.target.value);
        setDonationLinkInputField(e.target.value);
    };

    const handlePlaceholderLinkChange = (e: any) => {
        console.log('Placeholder link changed:', e.target.value);
        setPlaceholderLinkInputField(e.target.value);
    };

    const handleSave = async () => {
        console.log('Saving settings...');
        try {
            dispatch({ type: 'START_LOADING' });
            const response = await saveStreamerSettings(token, donationLinkInputField, placeholderLinkInputField, alertsButton);
            if (response.ok) {
                const updatedStreamerProfile = await response.json();
                setIsChanged(false);
                dispatch(setUserStreamerProfile(updatedStreamerProfile));  
                console.log("we overwrote the settings?");
            } else {
                console.error('Failed to save user settings');
            }
        } catch (error) {
            console.error('Error:', error);
        }
        dispatch({ type: 'STOP_LOADING' });
    };


    return (
        <>
            <div className="add-platform-container">
                <SaveButton onSave={handleSave} isDisabled={!isChanged} />
            </div>
            <div className="streamer-settings-options-container">
                <InputWidth
                    label="Donation Link:"
                    placeholder="Enter donation link"
                    value={donationLinkInputField}
                    onChange={handleDonationLinkChange}
                />
                <InputWidth
                    label="Extra Placeholder:"
                    placeholder="Nothing goes here, but you can put something if you want"
                    value={placeholderLinkInputField}
                    onChange={handlePlaceholderLinkChange}
                />
                <Checkbox
                    label="Show Alerts in Chat:"
                    checked={alertsButton}
                    onChange={(e) => setAlertsButton(e.target.checked)}
                />
            </div>
        </>
    );
};

export default StreamerSettings;
