// src/components/InteractiveSection.js
import React from 'react';
import SocialsBox from './SocialsBox';
import './InteractiveSection.css'; // New CSS file for styling
import FollowButtonBox from './FollowButtonBox';

const InteractiveSection = () => {

    // console.log("DID WE INTERACTIVE BOX");
    return (
        <div className="interactiveSection">
            <FollowButtonBox />
            <SocialsBox />
        </div>
    );
};

export default InteractiveSection;
