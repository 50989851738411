import { SERVER_URL } from "../../../../../../../env"

// saveStreamerSettings.ts
export const saveStreamerSettings = async (
  token: string | null,
  donationLink: string,
  placeholderLink: string,
  alertButton: boolean
): Promise<any> => {
  const response = await fetch(`${SERVER_URL}/streamer/options/settings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "include",
    body: JSON.stringify({
      settings: {
        donateButton: donationLink,
        placeholder: placeholderLink,
        alertsButton: alertButton,
      },
    }),
  })
  return response
}
