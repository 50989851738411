//TODO: Make it so when we embed an iframe we name it after its clientId that way we can send a message to only one of them lol
export function sendMessageToAllIframes(action: string, data: any) {
    const iframes = document.getElementsByTagName('iframe');
    
    for (let i = 0; i < iframes.length; i++) {
      const iframeWindow = iframes[i].contentWindow;
  
      if (iframeWindow) {
        console.log(`Sending message to iframe ${i}`);
        iframeWindow.postMessage(
          {
            action,
            data,
          },
          '*' // Replace '*' with actual target origin for security
        );
      } else {
        console.warn(`Could not access contentWindow for iframe ${i}`);
      }
    }
  }
  