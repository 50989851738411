import { SERVER_URL } from "../../../../../../env"
import { PlatformBar } from "./IPlatformBar"

export const apiSavePlatformConfiguration = async (
  platformBars: PlatformBar[],
  token: string | null
) => {
  if (!token) throw new Error("Unauthorized.")

  const response = await fetch(
    `${SERVER_URL}/streamer/options/embeddablePlatforms`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(platformBars),
      credentials: "include",
    }
  )

  if (!response.ok) throw new Error("Failed to save platform configurations")

  return await response.json()
}
