import { configureStore } from "@reduxjs/toolkit"
import { currentChatPlatformReducer } from "./reducers/currentChatPlatformReducer"
import editModeReducer from "./reducers/editModeReducer"
import fullscreenModeReducer from "./reducers/fullScreenReducer"
import inputChatReducer from "./reducers/inputChatReducer"
import loadingReducer from "./reducers/loadingReducer"
import settingsReducer from "./reducers/settingsReducer"
import theatreModeReducer from "./reducers/theatreModeReducer"
import userDeveloperProfileReducer from "./reducers/userDeveloperProfileReducer"
import userStreamerProfileReducer from "./reducers/userStreamerProfileReducer"

import authReducer from "./slices/Auth"
import chatReducer from "./slices/Chat"
import embeddedPlayerReducer from "./slices/EmbeddedPlayer"
import streamerPageReducer from "./slices/StreamerPage"

import streamerExtensionsReducer from "./reducers/streamerExtensionReducer"

const store = configureStore({
  reducer: {
    fullscreenMode: fullscreenModeReducer,
    theatreMode: theatreModeReducer, // Replace settings with theatreMode
    settings: settingsReducer,
    loading: loadingReducer,
    userStreamerProfile: userStreamerProfileReducer, // Add the reducer to the store
    userDeveloperProfile: userDeveloperProfileReducer,
    currentChatPlatform: currentChatPlatformReducer,
    chatInput: inputChatReducer,
    editMode: editModeReducer, // Add the editMode reducer

    // Slices
    embeddedPlayer: embeddedPlayerReducer,
    streamerPage: streamerPageReducer,
    auth: authReducer,
    chat: chatReducer,

    //Extension
    streamerExtensions: streamerExtensionsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export default store
