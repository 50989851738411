import { useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  setPlayerOverlayItem,
  togglePlayerOverlay,
} from "../store/slices/EmbeddedPlayer"
import usePlayerScale from "./usePlayerScale"

interface UseInventoryItemMessage {
  type: "USE_INVENTORY_ITEM"
  payload: {
    alertUrl: string
    duration: number
    productId: string
  }
}

export function useInventoryItem() {
  const dispatch = useDispatch()

  const scale = usePlayerScale()

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!isUseInventoryItem(event.data)) return

      const { payload } = event.data
      const type = resolveMediaType(payload.alertUrl)

      switch (type) {
        case "webm": {
          const media = document.createElement("video")

          media.src = payload.alertUrl
          media.onloadeddata = () => {
            const { videoWidth, videoHeight } = media

            dispatch(
              setPlayerOverlayItem({
                type: "video/webm",
                productId: payload.productId,
                src: media.src,
                width: videoWidth * scale.width,
                height: videoHeight * scale.height,
                duration: payload.duration,
              })
            )

            dispatch(togglePlayerOverlay(true))
          }

          break
        }

        default:
          console.log(`Unsupported media type: ${type}`)
      }
    }

    window.addEventListener("message", handleMessage)
    return () => window.removeEventListener("message", handleMessage)
  }, [dispatch, scale.height, scale.width])
}

function isUseInventoryItem(data: unknown): data is UseInventoryItemMessage {
  return (
    typeof data === "object" &&
    data != null &&
    "type" in data &&
    data.type === "USE_INVENTORY_ITEM" &&
    "payload" in data &&
    typeof data.payload === "object"
  )
}

function resolveMediaType(url: string) {
  const { pathname } = new URL(url)
  const match = /\.([^.]+)$/.exec(pathname)

  if (!match) {
    throw new Error(`Failed to resolve media type from URL: ${url}`)
  }

  return match[1] as any
}
