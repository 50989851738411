import { Tabs, TabsContent } from "@radix-ui/react-tabs"
import { AspectRatio } from "@radix-ui/themes"
import { useSelector } from "react-redux"
import { css, styled } from "styled-components"
import { RootState } from "../../store"
import { getAllExtensionJWT } from "../../store/slices/Auth"
import { getStreamerPageBottomBarExtensions } from "../../store/slices/StreamerPage"
import AuthModule from "../auth/login/AuthModule"
import { useAuthModule } from "../auth/login/UseAuthModule"
import { useMobileContext } from "../contexts/MobileContext"
import { useOrientation } from "../contexts/OrientationContext"
import { useToken } from "../contexts/TokenContext"
import { useUserProfile } from "../contexts/UserProfileContext"
import AlertList from "../extensions/native/AlertList"
import ShimmerPlayer from "../username/player/embed/ShimmerPlayer"
import BottomBar from "./chatBottomBar/ChatBottomBar"
import BottomBarPointsOnly from "./chatBottomBar/ChatBottomBarPointsOnly"
import ChatDisplay from "./chatDisplay/ChatDisplay"
import ExtensionBottomBar from "./chatExtensionBar/ChatExtensionBar"
import ChatInput from "./chatinput/ChatInput"

function ChatArea() {
  const { isLandscape } = useOrientation()
  const { isMobile } = useMobileContext()
  const { token } = useToken()
  const { isAuthModuleOpen, closeAuthModule } = useAuthModule()

  const { profile, profileFetched } = useUserProfile()

  const extensions = useSelector(
    (state: RootState) =>
      // TODO: Move userProfile to the store so we can get rid of this garbage selector
      getStreamerPageBottomBarExtensions(state)(profile?.administrator),
    { equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b) }
  )

  const getExtensionJWT = useSelector(getAllExtensionJWT)

  // useEffect(() => {
  //   console.log("ChatArea component mounted")
  //   return () => console.log("ChatArea component unmounted")
  // }, [])

  // Only show player if:
  // - user is logged in, profile is fetched, and player is enabled
  // - user is not logged in
  const showPlayer = token
    ? profileFetched
      ? !profile?.settings.disablePlayer
      : false
    : true

  return (
    //Margin of 5 px so the chat doesn't hug the player
    <Wrapper $landscape={isLandscape} $mobile={isMobile}>
      {isMobile && showPlayer && (
        <div id="player" style={{ marginBottom: "8px" }}>
          <AspectRatio ratio={16 / 9}>
            <ShimmerPlayer />
          </AspectRatio>
        </div>
      )}

      <ChatContainer defaultValue="chat">
        <ChatContent
          value="chat"
          forceMount
          $hasBottomBarExtensions={extensions.length > 0}
        >
          <ChatDisplay />
          <AlertList />
          <ChatInput />
          <BottomBar />
        </ChatContent>

        {extensions.map(({ clientId, buildUrl, extensionName }) => (
          <ChatContent
            key={clientId}
            value={clientId}
            forceMount
            $hasBottomBarExtensions
          >
            <ExtensionEmbed
              src={`${buildUrl}?token=${getExtensionJWT(clientId)}`}
              title={`${clientId} Extension`}
              allowFullScreen
              onLoad={() => {
                console.log("BETA Iframe for extension rendered:", clientId)
              }}
            />
            {/* <ChatInput /> */}
            <BottomBarPointsOnly extensionName={extensionName} />
          </ChatContent>
        ))}

        <ExtensionBottomBar />
      </ChatContainer>

      {isAuthModuleOpen && <AuthModule onClose={closeAuthModule} />}
    </Wrapper>
  )
}

const ChatContent = styled(TabsContent)<{ $hasBottomBarExtensions: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${({ $hasBottomBarExtensions }) =>
    `calc(100% - ${$hasBottomBarExtensions ? "40px" : "0px"})`};

  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(100%);
  z-index: 5;

  transition: transform 150ms;

  &[data-state="active"] {
    transform: translateX(0);
  }
`

const ChatContainer = styled(Tabs)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  height: 100%;
`

const ExtensionEmbed = styled.iframe`
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div<{ $landscape: boolean; $mobile: boolean }>`
  width: ${({ $landscape }) => ($landscape ? "35%" : "var(--chatarea-width)")};
  flex-shrink: 0;
  background-color: var(--sidebar-color);
  color: #ecf0f1;

  display: flex;
  flex-direction: column;

  ${({ $mobile }) =>
    $mobile &&
    css`
      flex-grow: 1;
    `}

  @media (max-width: 400px) {
    width: 100%; /* Full width on mobile */
  }
`

export default ChatArea
