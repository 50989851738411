import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface InventoryItem {
  type: "video/webm" // supports webm for now
  src: string
  width: number
  height: number
  duration: number
  productId: string
}

export interface EmbeddedPlayerState {
  index: number
  platform: string
  url: string
  overlay: {
    show: boolean
    data?: InventoryItem
  }
}

const initialState: EmbeddedPlayerState = {
  index: 0,
  platform: "",
  url: "",
  overlay: { show: false },
}

const embeddedPlayer = createSlice({
  name: "embeddedPlayer",
  initialState,
  reducers: {
    setActivePlayer: (
      state,
      action: PayloadAction<Omit<EmbeddedPlayerState, "overlay">>
    ) => {
      state.index = action.payload.index
      state.platform = action.payload.platform
      state.url = action.payload.url
    },

    resetPlayer: (state) => (state = initialState),

    toggleOverlay: (state, action: PayloadAction<boolean>) => {
      state.overlay.show = action.payload
    },

    setOverlayItem: (state, action: PayloadAction<InventoryItem>) => {
      state.overlay.data = action.payload
      state.overlay.show = true
    },

    resetOverlay: (state) => {
      state.overlay.data = undefined
      state.overlay.show = false
    },
  },

  selectors: {
    getActivePlayer: (state) => state,
    getShowOverlay: (state) => state.overlay.show,
    getOverlayItem: (state) => state.overlay.data,
  },
})

export const {
  setActivePlayer,
  resetPlayer,
  toggleOverlay: togglePlayerOverlay,
  setOverlayItem: setPlayerOverlayItem,
  resetOverlay: resetPlayerOverlay,
} = embeddedPlayer.actions

export const { getActivePlayer, getShowOverlay, getOverlayItem } =
  embeddedPlayer.selectors

export default embeddedPlayer.reducer
