import { createContext, PropsWithChildren, ReactNode, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import MiniSearch, { Query, SearchOptions, SearchResult as MiniSearchResult } from 'minisearch';
import { getCommands } from '../api/commands/Commands'; // This is where you will fetch the commands JSON

interface SearchIndexable {
  id: string;
  name: string;
}

export interface CommandMeta extends SearchIndexable {
  description: string;
  platforms: { name: string; permissions: string[] }[];
  example: string;
}

interface CommandMetadata {
  commands: CommandMeta[];
  search: (query: Query, options?: SearchOptions) => SearchResult<CommandMeta>[];
}

type SearchResult<T> = MiniSearchResult & T;
export type CommandSearchResult = SearchResult<CommandMeta>;

const CommandContext = createContext<CommandMetadata | null>(null);

export function useCommands() {
  const context = useContext(CommandContext);
  if (!context) {
    throw new Error('useCommands must be used within a CommandProvider.');
  }
  return context;
}

const SearchEngine = new MiniSearch<SearchIndexable>({
  fields: ['name', 'description'],
  storeFields: ['name', 'description', 'platforms', 'example'],
});

function CommandProvider({ children }: PropsWithChildren) {
  const [commands, setCommands] = useState<CommandMeta[]>([]);

  useEffect(() => {
    // Fetch command data (this can be from an API or a local file)
    getCommands()
      .then((data) => {
        setCommands(data);
        console.log("Commands fetched and set in state:", data); // Debug log

        data.forEach(addCommandToIndex);
      })
      .catch((error) => {
        console.error('Error fetching commands:', error);
      });

    return () => {
      SearchEngine.removeAll(); // Cleanup search index
    };
  }, []);

  const addCommandToIndex = (command: CommandMeta) => {
    if (!SearchEngine.has(command.id)) SearchEngine.add(command);
  };

  const search = useCallback<CommandMetadata['search']>(
    (query: Query, options?: SearchOptions) =>
      SearchEngine.search(query, {
        fuzzy: 0.2,
        prefix: true,
        ...options,
      }) as SearchResult<CommandMeta>[],
    []
  );

  const value = useMemo<CommandMetadata>(
    () => ({ commands, search }),
    [commands, search]
  );

  return <CommandContext.Provider value={value}>{children}</CommandContext.Provider>;
}

export default CommandProvider;
