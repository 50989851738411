import { SERVER_URL } from "../env"
import { ChatRPGBadge } from "../types/ChatRPGBadge"
import { GlobalBadge } from "../types/GlobalBadge"
import { KickBadge } from "../types/KickBadge"
import { YoutubeBadge } from "../types/YoutubeBadge"

export const fetchTwitchBadges = async (
  setGlobalBadges: React.Dispatch<React.SetStateAction<GlobalBadge[]>>
) => {
  try {
    const response = await fetch(`${SERVER_URL}/global/badges`)

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const badges: GlobalBadge[] = await response.json()

    setGlobalBadges(badges)
  } catch (error) {
    console.error("Failed to fetch and store Twitch badges:", error)
  }
}

export const fetchChatRPGBadges = async (
  setChatRPGBadges: React.Dispatch<React.SetStateAction<ChatRPGBadge[]>>
) => {
  try {
    const response = await fetch(`${SERVER_URL}/global/chatrpgbadges`)

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const badges: ChatRPGBadge[] = await response.json()
    setChatRPGBadges(badges)
  } catch (error) {
    console.error("Failed to fetch custom badges:", error)
  }
}

export const fetchKickBadges = async (
  setKickBadges: React.Dispatch<React.SetStateAction<KickBadge[]>>
) => {
  try {
    const response = await fetch(`${SERVER_URL}/global/kickbadges`)

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const badges: ChatRPGBadge[] = await response.json()
    setKickBadges(badges)
  } catch (error) {
    console.error("Failed to fetch custom badges:", error)
  }
}

export const fetchYoutubeBadges = async (
  setYoutubeBadges: React.Dispatch<React.SetStateAction<YoutubeBadge[]>>
) => {
  try {
    const response = await fetch(`${SERVER_URL}/global/youtubebadges`)

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const badges: ChatRPGBadge[] = await response.json()
    setYoutubeBadges(badges)
  } catch (error) {
    console.error("Failed to fetch custom badges:", error)
  }
}
