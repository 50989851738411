import { Skeleton } from "@radix-ui/themes"
import { useSelector } from "react-redux"
import { getActivePlayer } from "../../../../store/slices/EmbeddedPlayer"
import { useStreamerProfile } from "../../../contexts/StreamerProfileContext"
import EmbeddedPlayer from "./EmbeddedPlayer"

function ShimmerPlayer() {
  const { status } = useStreamerProfile()
  const { platform, url } = useSelector(getActivePlayer)

  if (status === "loading") {
    return <Skeleton height="100%" />
  }

  if (platform && url) {
    return <EmbeddedPlayer platform={platform} url={url} />
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      No embedded player available.
    </div>
  )
}

export default ShimmerPlayer
