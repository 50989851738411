import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useLocation } from "react-router-dom"
import { useSessionStorage } from "usehooks-ts"
import { WEBSOCKET_URL } from "../../env"
import { logWithBlueBackground } from "../../utils/logging"
import { useStreamerProfile } from "./StreamerProfileContext"
import { useToken } from "./TokenContext"

interface WebsocketBaseState {
  resetAttempts: () => void
}

interface WebSocketConnectingState extends WebsocketBaseState {
  socket: null
  status: "connecting"
}

interface WebSocketConnectedState extends WebsocketBaseState {
  socket: WebSocket
  status: "connected"
}

interface WebSocketDisconnectedState extends WebsocketBaseState {
  socket: WebSocket
  status: "disconnected"
}

interface WebSocketErrorState extends WebsocketBaseState {
  socket: null
  status: "error"
}

type IWebSocketContext =
  | WebSocketDisconnectedState
  | WebSocketConnectingState
  | WebSocketConnectedState
  | WebSocketErrorState

const WebSocketContext = createContext<IWebSocketContext | null>(null)

export function useWebSocket() {
  const context = useContext(WebSocketContext)

  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider")
  }

  return context
}

function generateTabId() {
  return Math.random().toString(36).substring(2, 9)
}

function logCurrentState(socket: WebSocket, attempts: number) {
  switch (socket.readyState) {
    case socket.CONNECTING: // 0
      logWithBlueBackground(
        `[${socket.readyState}] Chat Socket: Connecting... Attempts: ${attempts}`
      )
      break

    case socket.OPEN: // 1
      logWithBlueBackground(`[${socket.readyState}] Chat Socket: Open!`)
      break

    case socket.CLOSING: // 2
      logWithBlueBackground(
        `[${socket.readyState}] Chat Socket: Packing up and closing :(`
      )
      break

    case socket.CLOSED: // 3
      logWithBlueBackground(
        `[${socket.readyState}] Chat Socket: We already closed.`
      )
      break

    default:
      logWithBlueBackground(
        `[${socket.readyState}] Chat Socket: this shouldn't happen???`
      )
  }
}

const MAX_RETRIES = 7
const url = WEBSOCKET_URL || "ws://localhost:4002"

function WebSocketProvider({ children }: PropsWithChildren) {
  const location = useLocation()

  const { token } = useToken()
  const { status: streamerPageStatus, streamerProfile } = useStreamerProfile()

  const [socket, setSocket] = useState<IWebSocketContext["socket"]>(null)
  const [status, setStatus] =
    useState<IWebSocketContext["status"]>("connecting")

  const [tabId] = useSessionStorage("tabId", generateTabId)

  const reconnectTimeoutId = useRef<NodeJS.Timeout | null>(null)
  const attempts = useRef(0)
  const clean = useRef(true)
  const tokenChanged = useRef(false)

  const connect = useCallback(() => {
    setStatus("connecting")
    console.log("HERE IS MY TAB ID!!!", tabId)

    attempts.current += 1
    const newSocket = new WebSocket(`${url}?tabId=${tabId}`)
    setSocket(newSocket)
  }, [tabId])

  const resetAttempts = useCallback(() => {
    // Only reset attempts if:
    // - The socket was closed cleanly, meaning either we initiated the close properly
    // - There were more than 1 attempts, and now we're successfully connected again
    if (clean.current || attempts.current > 1) attempts.current = 0
    if (reconnectTimeoutId.current) {
      clearTimeout(reconnectTimeoutId.current)
      reconnectTimeoutId.current = null
    }
  }, [])

  // Connecting
  useEffect(() => {
    // Always wait for the user AND streamer profile data to load first
    if (streamerPageStatus === "loading") return

    console.log("Chat Socket:", socket, {
      reconnectTimeoutId: reconnectTimeoutId.current,
      clean: clean.current,
      attempts: attempts.current,
    })

    if (!socket) {
      logWithBlueBackground(
        "Chat Socket: Looks like this is the first time we connect!"
      )

      return connect()
    }

    // Force reconnect if we are already disconnected
    // and somehow the `close` event was not fired and
    // there was no ongoing reconnect attempt
    if (socket.readyState === socket.CLOSED && !reconnectTimeoutId.current) {
      return connect()
    }

    return () => socket.close()
  }, [connect, socket, streamerPageStatus])

  // Connected
  useEffect(() => {
    if (!socket) return

    function connectHandler() {
      if (streamerPageStatus === "loading") {
        // If the streamer profile still hasn't loaded
        // at this point, then that's a big problem
        throw new Error("Missing streamer profile data.")
      }

      if (!socket) return

      setStatus("connected")

      logWithBlueBackground(
        `We are connected to ChatRPG chat. Are we reconnecting? ${
          !clean.current ? "Yes" : "No"
        } [${attempts.current}] `
      )

      const { chatrpg_id, twitch_username, chatrpg_username } = streamerProfile
      socket.send(
        JSON.stringify({
          token,
          streamer: twitch_username || chatrpg_username,
          streamerId: chatrpg_id,
          reconnecting: tokenChanged.current || !clean.current,
        })
      )

      if (tokenChanged) {
        tokenChanged.current = false
      }

      resetAttempts()
      logCurrentState(socket, attempts.current)
    }

    socket.addEventListener("open", connectHandler)
    return () => socket.removeEventListener("open", connectHandler)
  }, [resetAttempts, socket, streamerProfile, token])

  // Disconnected
  useEffect(() => {
    if (!socket) return

    function disconnectHandler(event: CloseEvent) {
      if (!socket) return

      if (attempts.current === 2) {
        setStatus("disconnected")
      }

      clean.current = event.wasClean
      logWithBlueBackground(
        `[CODE:${
          event.code
        }] We are disconnected from ChatRPG chat. Was it clean? ${
          event.wasClean ? "Yes" : "No"
        }. Server Reason: ${event.reason || "None provided."}`
      )
      console.log(event)

      if (event.code === 4000) {
        console.log(
          `Connection closed due to duplicate tabId: ${tabId}. Not attempting to reconnect.`
        )

        sessionStorage.removeItem("tabId") // Clear the old tabId
      }

      if (attempts.current > MAX_RETRIES) {
        setStatus("error")
        return socket.close(3000, "MAX_RETRIES")
      }

      logCurrentState(socket, attempts.current)
      logWithBlueBackground(
        `We're attempting to reconnect: ${attempts.current} [MAX:${MAX_RETRIES}]`
      )

      reconnectTimeoutId.current = setTimeout(
        () => connect(),
        Math.pow(2, attempts.current) * 1200 // Exponential backoff
      )
    }

    socket.addEventListener("close", disconnectHandler)
    return () => socket.removeEventListener("close", disconnectHandler)
  }, [connect, socket, tabId])

  // Error
  useEffect(() => {
    if (!socket) return

    function errorHandler(event: Event) {
      if (!socket) return

      console.error("WebSocket encountered an error:", event)
      socket.close()

      logCurrentState(socket, attempts.current)
    }

    socket.addEventListener("error", errorHandler)
    return () => socket.removeEventListener("error", errorHandler)
  }, [socket])

  // Close socket and reset attempts on location change
  // Should be streamer change tho
  useEffect(() => {
    if (!socket) return

    socket.close()
  }, [location.pathname])

  useEffect(() => {
    if (!socket) return

    tokenChanged.current = true
    socket.close()
  }, [token])

  const value = useMemo<IWebSocketContext>(
    // @ts-expect-error wat in the hec
    () => ({ socket, status, resetAttempts }),
    [resetAttempts, socket, status]
  )

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  )
}

export default WebSocketProvider
