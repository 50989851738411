import React from 'react';
import styled from 'styled-components';

interface CheckboxProps {
    className?: string; // Make className optional
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
}

const CheckboxContainer = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 0.875rem;
    user-select: none;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const StyledCheckbox = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ccc;
    border-radius: 4px;

    ${HiddenCheckbox}:checked + & {
        background-color: #4A90E2;
    }

    ${HiddenCheckbox}:checked + &::after {
        display: block;
    }

    &::after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 3px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;

const Checkbox = ({ className, checked, onChange, label }: CheckboxProps) => (
    <CheckboxContainer className={className}>
        {label}
        <HiddenCheckbox checked={checked} onChange={onChange} />
        <StyledCheckbox />
    </CheckboxContainer>
);

export default Checkbox;
