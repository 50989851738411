import { SERVER_URL } from '../../../../../../env';
import store from '../../../../../../store';
import { updateStreamerPagePanels } from '../../../../../../store/slices/StreamerPage';

const handlePanelRemove = async (
  panelId: string,
  token: string | null,
  dispatch: Function,
  // setStreamerProfile: React.Dispatch<React.SetStateAction<any>>
) => {
  if (!panelId || !token) return;

  dispatch({ type: 'START_LOADING' });
  try {
    const response = await fetch(`${SERVER_URL}/streamer/removepanel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ id: panelId }),
    });

    const responseData = await response.json();
    if (responseData && responseData.panels) {
      store.dispatch(updateStreamerPagePanels(responseData.panels))
      console.log('Remaining panels:', responseData.panels);
    }
    dispatch({ type: 'STOP_LOADING' });
  } catch (error) {
    console.error('Error removing panel:', error);
    dispatch({ type: 'STOP_LOADING' });
  }
};

export default handlePanelRemove;
