import {
  UIEvent as ReactUIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { getChatPaused, setChatPaused } from "../../../store/slices/Chat"
import ChatMessage from "../chatmessage/ChatMessageModel"

interface ChatScrollOptions {
  max?: number
  onChange?: (messages: ChatMessage[]) => void
}

const isScrolledToBottom = (element: HTMLDivElement) =>
  element.scrollHeight - element.clientHeight <= element.scrollTop + 5

const isOverflowing = (element: HTMLDivElement) =>
  element.scrollHeight > element.offsetHeight

const useChatScroll = (
  messages: ChatMessage[],
  buffer: ChatMessage[],
  { max = 100, onChange }: ChatScrollOptions
) => {
  const dispatch = useDispatch()

  const isChatPaused = useSelector(getChatPaused)

  const [pausedMessageCount, setPausedMessageCount] = useState(0)

  const scrollElementRef = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(
    (event: ReactUIEvent<HTMLDivElement, UIEvent>) => {
      const shouldPause = !isScrolledToBottom(event.currentTarget)
      if (shouldPause !== isChatPaused) {
        dispatch(setChatPaused(shouldPause))
      }
    },
    [dispatch, isChatPaused]
  )

  const updatePausedMessageCount = useCallback(() => {
    setPausedMessageCount((count) => {
      const element = scrollElementRef.current
      if (!element) return count
      if (isScrolledToBottom(element) || !isOverflowing(element)) return 0
      return Math.min(count + 1, max)
    })
  }, [max])

  const scrollToBottom = useCallback(
    (opts?: { force?: boolean }) => {
      if (!scrollElementRef.current?.lastElementChild) return

      // TODO uhhhh
      if (!isOverflowing(scrollElementRef.current)) {
        return void dispatch(setChatPaused(false))
      }

      if (!isChatPaused || opts?.force) {
        scrollElementRef.current.lastElementChild.scrollIntoView({
          // behavior: "smooth",
          block: "end",
          inline: "nearest",
        })
      }
    },
    [dispatch, isChatPaused]
  )

  useEffect(() => {
    updatePausedMessageCount()
  }, [buffer, updatePausedMessageCount])

  useEffect(() => {
    // onChange(messages)
    scrollToBottom()
  }, [messages, scrollToBottom])

  return useMemo(
    () => ({
      handleScroll,
      scrollToBottom,
      scrollElementRef,
      showScrollToBottomButton: isChatPaused,
      pausedMessageCount,
    }),
    [handleScroll, isChatPaused, pausedMessageCount, scrollToBottom]
  )
}

export default useChatScroll
