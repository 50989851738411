import { TabsList } from "@radix-ui/react-tabs"
import styled from "styled-components"
import { useUserOnStreamer } from "../../contexts/UserOnStreamerProfileContext"
import { useUserProfile } from "../../contexts/UserProfileContext"
import { media } from "../../newlanding/styled.utils"
import AdminSettings from "./categories/admincategory/AdministratorSettings"
import DeveloperSettings from "./categories/developercategory/developersettings/DeveloperSettings"
import ExtensionSettings from "./categories/extensioncategory/ExtensionSettings"
import StreamerSettings from "./categories/streamercategory/streamersettings/StreamerSettings"
import UserSettings from "./categories/usercategory/UserSettings"
import UserOnStreamerSettings from "./categories/useronstreamercategory/UserOnStreamerSettings"

function SettingsSidebar() {
  const { profile } = useUserProfile()
  const { userOnStreamer } = useUserOnStreamer()

  return (
    <Wrapper>
      {profile?.administrator ? <AdminSettings /> : null}

      <UserSettings />

      {profile?.isStreamer && <StreamerSettings />}

      {/* {userOnStreamer ? <UserOnStreamerSettings /> : null} */}

      {profile?.isStreamer ? <ExtensionSettings /> : null}

      {profile?.isDeveloper && <DeveloperSettings />}
    </Wrapper>
  )
}

const Wrapper = styled(TabsList)`
  background: #2b2d31;
  padding: 20px;
  inset: 0;
  z-index: 5;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  max-width: fit-content;
  overflow-y: scroll;

  ${media.md`
    position: relative;
    min-width: 16rem; 
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  `}

  & > :not([hidden]) ~ :not([hidden], :last-child) {
    margin: 2rem 0;
  }
`

export default SettingsSidebar
