import { useDispatch } from "react-redux"
import styled, { keyframes } from "styled-components"
import { ActiveUserSearchResult } from "../../providers/ActiveUserProvider"
import { CommandSearchResult } from "../../providers/CommandProvider"
import { EmoteSearchResult } from "../../providers/EmoteProvider"
import { setChatMessage } from "../../store/reducers/inputChatReducer"
import { PopoverContent } from "../ui/Popover.styled"

interface EmotePopoverProps {
  emotes: EmoteSearchResult[]
  commands?: CommandSearchResult[] // Optional commands array
  activeUsers?: ActiveUserSearchResult[] // Optional active users array
  message: string
  onClose: () => void
}

function EmotePopover({
  emotes,
  commands,
  activeUsers,
  message,
  onClose,
}: EmotePopoverProps) {
  const dispatch = useDispatch()

  const disableAutoFocus = (e: Event) => e.preventDefault()

  if (
    !emotes.length &&
    (!commands || !commands.length) &&
    (!activeUsers || !activeUsers.length)
  )
    return null

  const items = emotes.length
    ? emotes
    : commands?.length
    ? commands
    : activeUsers?.length
    ? activeUsers
    : []

  return (
    <Content
      side="top"
      sideOffset={10}
      noArrow
      onOpenAutoFocus={disableAutoFocus}
      onCloseAutoFocus={disableAutoFocus}
    >
      {/* Ensure correct rendering of items */}
      {items.map((item) => {
        if (emotes.length) {
          return (
            <EmoteItem
              key={item.id}
              item={item}
              message={message}
              onClose={onClose}
              dispatch={dispatch}
            />
          )
        } else if (commands?.length) {
          return (
            <CommandItem
              key={item.id}
              item={item}
              message={message}
              onClose={onClose}
              dispatch={dispatch}
            />
          )
        } else if (activeUsers?.length) {
          return (
            <UserItem
              key={item.id}
              item={item}
              message={message}
              onClose={onClose}
              dispatch={dispatch}
            />
          )
        } else {
          return null
        }
      })}
    </Content>
  )
}

const EmoteItem = ({ item, message, onClose, dispatch }) => {
  return (
    <EmoteButton
      onClick={() => {
        const newText = message.replace(/:?\b\w+\b$/i, `${item.name} `) // Replace text with emote
        dispatch(setChatMessage(newText))
        onClose()
      }}
    >
      {item.url && (
        <img
          src={item.url}
          alt={item.name}
          title={item.name}
          width={32} /* Ensure the emotes are tiny */
          height={32}
          style={{ width: "auto", height: "auto" }} /* Maintain aspect ratio */
        />
      )}
      <span>{item.name}</span>
    </EmoteButton>
  )
}

const CommandItem = ({ item, message, onClose, dispatch }) => {
  return (
    <CommandButton
      onClick={() => {
        const newText = message.replace(/\/\b\w+\b$/i, `/${item.name} `) // Replace text with command
        dispatch(setChatMessage(newText))
        onClose()
      }}
    >
      <CommandName>
        <CommandColoredName>/{item.name}</CommandColoredName>{" "}
        {item.example && <CommandParams>{item.example}</CommandParams>}
      </CommandName>
      <Description>{item.description}</Description>
      <Platforms>
        {item.platforms && (
          <div>
            {item.platforms?.map((platform, index) => (
              <PlatformName key={platform.name}>
                {platform.name}
                {index < item.platforms.length - 1 ? ", " : ""}
              </PlatformName>
            ))}
          </div>
        )}
      </Platforms>
    </CommandButton>
  )
}

const UserItem = ({ item, message, onClose, dispatch }) => {
  return (
    <UserButton
      onClick={() => {
        const newText = message.replace(/@\b\w+\b$/i, `@${item.name} `) // Replace text with username
        dispatch(setChatMessage(newText))
        onClose()
      }}
    >
      <UserName>@{item.name}</UserName> {/* Display user name */}
      <PlatformTag>{item.platform}</PlatformTag> {/* Display user platform */}
    </UserButton>
  )
}

//ACTIVE USERS vvvvvvvvvvvvvvvv
const UserButton = styled.button`
  display: flex;
  justify-content: space-between; /* Username on left, platform on right */
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: 100%;

  &:hover {
    background-color: #a8b0cc28;
  }
`

const UserName = styled.span`
  font-size: 1rem;
  color: var(--chatrpg-logo-color);
`

const PlatformTag = styled.span`
  font-size: 0.75rem;
  color: #39a0ed;
`

//ACTIVE USERS ^^^^^^^^^^^
//COMMANDS vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

const CommandParams = styled.span`
  font-size: 0.75rem;
  color: #aaa; /* Light grey for the parameters */
  margin-left: 0.5rem; /* Add space between command name and parameters */
`

const Platforms = styled.div`
  display: flex;
  margin-top: -0.2rem;
  flex-wrap: wrap; /* Allow platforms to wrap to the next line if necessary */
  gap: 0.375rem; /* Add space between each platform */
`

const PlatformName = styled.span`
  font-size: 0.65rem;
  color: #ffffff; /* Make sure the platform text is readable */
`

const Description = styled.div`
  font-size: 0.75rem;
  color: #ccc;
`

const CommandButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensure all elements are aligned to the left */
  line-height: 1;
  gap: 0.5rem;
  padding: 0.3rem;
  border-radius: 0.25rem;
  text-align: left;

  &:hover {
    background-color: #a8b0cc28;
  }
`

const CommandColoredName = styled.div`
  color: var(--chatrpg-logo-color); /* Gold color for command names */
`

const CommandName = styled.div`
  display: flex;
  align-items: center; /* Vertically center the content */
`

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Content = styled(PopoverContent)`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  min-width: 24.125rem;
  max-height: 70vh;
  overflow-y: scroll;
  padding: 0.65rem;
  border-radius: 0.5rem;

  font-size: 0.875rem;
  background-color: var(--sidebar-color);
  border: 1px solid #999999;

  transform-origin: var(--radix-popover-content-transform-origin);
  animation: ${scaleIn} 125ms;
`
//COMMANDS ^^^^^^^^^^^^^^^^^^^^^^^

//EMOTICONS vvvvvvvvvvvvvvvvvvvv
const EmoteButton = styled.button`
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 0.375rem;
  padding: 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: #a8b0cc28;
  }

  span {
    color: white;
  }

  span:nth-child(3) {
    margin-left: -0.125rem;
    color: #39a0ed;
  }
`

export default EmotePopover
