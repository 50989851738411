import Content from "components/Content"
import { AlertVisibilityProvider } from "components/contexts/AlertVisibilityContext"
import { MobileContextProvider } from "components/contexts/MobileContext"
import { OrientationProvider } from "components/contexts/OrientationContext"
import TokenProvider from "components/contexts/TokenContext"
import UserProfileProvider from "components/contexts/UserProfileContext"
import { BrowserRouter as Router } from "react-router-dom"

import "./App.css"

function App() {
  return (
    <Router>
      <TokenProvider>
        <UserProfileProvider>
          <MobileContextProvider>
            <AlertVisibilityProvider>
              <OrientationProvider>
                <Content />
              </OrientationProvider>
            </AlertVisibilityProvider>
          </MobileContextProvider>
        </UserProfileProvider>
      </TokenProvider>
    </Router>
  )
}

export default App
