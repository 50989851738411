import { createContext, PropsWithChildren, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MiniSearch, { Query, SearchOptions, SearchResult as MiniSearchResult } from 'minisearch';

// Define the structure for the active user metadata
interface SearchIndexable {
    id: string;
    name: string;
    platform: string;
}

export interface ActiveUserMeta extends SearchIndexable {
    platform: string;
    lastActive: Date;
}

interface ActiveUserMetadata {
    users: ActiveUserMeta[];
    search: (query: Query, options?: SearchOptions) => SearchResult<ActiveUserMeta>[];
    addUser: (user: ActiveUserMeta) => void;
}

type SearchResult<T> = MiniSearchResult & T;
export type ActiveUserSearchResult = SearchResult<ActiveUserMeta>;

// Create the ActiveUser context
const ActiveUserContext = createContext<ActiveUserMetadata | null>(null);

// Hook to use the context
export function useActiveUsers() {
    const context = useContext(ActiveUserContext);
    if (!context) {
        throw new Error('useActiveUsers must be used within an ActiveUserProvider.');
    }
    return context;
}

// MiniSearch instance for ActiveUsers
const SearchEngine = new MiniSearch<SearchIndexable>({
    fields: ['name', 'platform'],
    storeFields: ['name', 'platform', 'lastActive'],
});

const MAX_USERS = 50; // Maximum number of active users allowed

// ActiveUserProvider component
function ActiveUserProvider({ children }: PropsWithChildren) {
    const [users, setUsers] = useState<ActiveUserMeta[]>([]);

    const addUser = useCallback((user: ActiveUserMeta) => {
        setUsers((prevUsers) => {
            const existingUser = prevUsers.find((u) => u.id === user.id);
            let updatedUsers;

            if (existingUser) {
                // Update the existing user's lastActive timestamp
                updatedUsers = prevUsers.map((u) =>
                    u.id === user.id ? { ...u, lastActive: user.lastActive } : u
                );
            } else {
                // Add new user and remove the oldest user if limit is exceeded
                updatedUsers = [...prevUsers, user];
                if (updatedUsers.length > MAX_USERS) {
                    const removedUser = updatedUsers.shift(); // Remove the oldest user
                    if (removedUser) {
                        removeUserFromIndex(removedUser); // Ensure the user is removed from the search index
                    }
                }
            }

            return updatedUsers;
        });

        // Add the user to the search engine
        addUserToIndex(user);
    }, []);

    // Add user to the search index
    const addUserToIndex = (user: ActiveUserMeta) => {
        if (!SearchEngine.has(user.id)) {
            SearchEngine.add({ id: user.id, name: user.name, platform: user.platform });
        }
    };

    const removeUserFromIndex = (user: ActiveUserMeta) => {
        if (SearchEngine.has(user.id)) {
            SearchEngine.remove(user); // Remove full user object
        }
    };

    // Search function for active users
    const search = useCallback<ActiveUserMetadata['search']>(
        (query: Query, options?: SearchOptions) =>
            SearchEngine.search(query, {
                fuzzy: 0.2,
                prefix: true,
                ...options,
            }) as SearchResult<ActiveUserMeta>[],
        []
    );

    const value = useMemo<ActiveUserMetadata>(
        () => ({ users, search, addUser }),
        [users, search]
    );

    return <ActiveUserContext.Provider value={value}>{children}</ActiveUserContext.Provider>;
}

export default ActiveUserProvider;
