import { createAppSlice } from "../utils"

const slice = createAppSlice({
  name: "chat",
  initialState: {
    messages: [],
    buffer: [],
    paused: false,
  },
  reducers: (create) => ({
    setPaused: create.reducer<boolean>((state, action) => {
      state.paused = action.payload
    }),
  }),
  selectors: {
    getMessages: (state) => state.messages,
    getPaused: (state) => state.paused,
  },
})

export const { setPaused: setChatPaused } = slice.actions
export const { getMessages: getChatMessages, getPaused: getChatPaused } =
  slice.selectors
export default slice.reducer
