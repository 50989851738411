import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { styled } from "styled-components"
import { RootState } from "../../store"
import { setTheatreMode } from "../../store/reducers/theatreModeReducer"
import UsernamePrompt from "../auth/username/UsernamePromptBox"
import ChatArea from "../chat/ChatArea"
import { GlobalBadgeProvider } from "../contexts/GlobalBadgeContext"
import { useMobileContext } from "../contexts/MobileContext"
import { useOrientation } from "../contexts/OrientationContext"
import { UserNotFound } from "../misc/404/UserNotFound"
import Sidebar from "../sidebar/Sidebar"
import TopBar from "../topbar/TopBar"
import { useStreamerProfile } from "./../contexts/StreamerProfileContext"
import ContentAreaContainer from "./ContentAreaContainer"
import PlayerArea from "./player/PlayerArea"
import ChatDisplay from "components/chat/chatDisplay/ChatDisplay"

function UserPage({ isChatPage }: { isChatPage?: boolean }) {
  const dispatch = useDispatch()

  const { isLandscape } = useOrientation()
  const { isMobile } = useMobileContext()

  const { status } = useStreamerProfile()

  const isTheatreMode = useSelector(
    ({ theatreMode }: RootState) => theatreMode.isTheatreMode
  )
  const isFullscreenMode = useSelector(
    ({ fullscreenMode }: RootState) => fullscreenMode.isFullscreenMode
  )

  useEffect(() => {
    dispatch(setTheatreMode(isLandscape))
  }, [dispatch, isLandscape])

  if (isChatPage) {
    return (
      <Wrapper>
        <GlobalBadgeProvider>
          <ChatDisplay isEmbedded={true} />
        </GlobalBadgeProvider>
      </Wrapper>
    );
  }


  return (
    <Wrapper>
      <UsernamePrompt />
      <Sidebar />
      <ContentAreaContainer>
        {!isTheatreMode && !isFullscreenMode && <TopBar />}

        {status === "error" ? (
          <UserNotFound />
        ) : (
          <MainContent>
            {!isMobile && <PlayerArea />}

            {!isFullscreenMode && (
              <GlobalBadgeProvider>
                <ChatArea />
              </GlobalBadgeProvider>
            )}
          </MainContent>
        )}
      </ContentAreaContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  min-width: 400px;
  height: 100vh;
  max-width: 100%;
  overflow: clip;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1; /* Take remaining space after the top bar */
  height: calc(100vh - var(--top-bar-height));
  background-color: #0e0e10;
`

export default UserPage
