import { useEffect, useMemo, useState } from "react"

function usePlayerScale() {
  const [scale, setScale] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const updateScale = () => {
      const player = document.querySelector<HTMLIFrameElement>("#player iframe")

      if (!player) return

      const width = player.offsetWidth
      const height = player.offsetHeight

      const widthScale = width / 1920
      const heightScale = height / 1080

      setScale({ width: widthScale, height: heightScale }) // Update scale based on player size
    }

    updateScale()
    window.addEventListener("resize", updateScale) // Listen for window resize
    return () => window.removeEventListener("resize", updateScale)
  }, [])

  return useMemo(() => scale, [scale])
}

export default usePlayerScale
