import { Flex } from "@radix-ui/themes"
import axios from "axios"
import { MoveableManagerInterface } from "react-moveable"
import styled from "styled-components"
import { INVENTORY_CLIENT_ID, INVENTORY_SERVER_URL } from "../../../../env"
import store from "../../../../store"
import { getAllExtensionJWT } from "../../../../store/slices/Auth"
import { resetPlayerOverlay } from "../../../../store/slices/EmbeddedPlayer"
// import { getStreamerPageProfile } from "../../../../store/slices/StreamerPage"
import { sendMessageToAllIframes } from "../../../extensions/helper/SendMessageToIframe"

function normalize(value: number, { min = 0, max = 100, multiplier = 1 }) {
  return ((value - min) / (max - min)) * multiplier
}

export const Actionable = {
  name: "actionable",
  props: ["item"],
  events: [],
  render(moveable: MoveableManagerInterface<any, any>) {
    const rect = moveable.getRect()
    const { pos1, pos2, containerClientRect } = moveable.state

    const state = store.getState()
    // const profile = getStreamerPageProfile(state)
    const getToken = getAllExtensionJWT(state)
    //@ts-ignore - Token isn't matching up here but its whatever
    const token = getToken(INVENTORY_CLIENT_ID)

    const { item } = moveable.props

    const handleRemove = () => {
      store.dispatch(resetPlayerOverlay())
    }

    const handleUse = async () => {
      store.dispatch(resetPlayerOverlay())

      try {
        sendMessageToAllIframes(`${INVENTORY_CLIENT_ID}_START_LOADING`, {
          loading: true,
        })

        const response = await axios.post(
          `${INVENTORY_SERVER_URL}/inventory/use`,
          {
            inventoryItemId: item.productId,
            positionX: normalize(rect.origin[0], {
              // X position
              max: containerClientRect.width,
              multiplier: 1920,
            }),
            positionY: normalize(rect.origin[1], {
              // Y position
              max: containerClientRect.height,
              multiplier: 1080,
            }),
            scale: moveable.scale[0],
            rotation: rect.rotation,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.status === 200) {
          sendMessageToAllIframes(`${INVENTORY_CLIENT_ID}_STOP_LOADING`, {
            loading: false,
          })
          console.log("Item used successfully")
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const reason =
            error.response?.data?.message ||
            error.message ||
            "Unknown error occurred"

          alert(`Failed to use item. Reason: ${reason}`)

          console.error("Failed to use item: ", reason, error.response?.data)
        } else {
          alert(
            "An unexpected error occurred. Please try again. This ain't good"
          )
          console.error("Unexpected error:", error)
        }
      } finally {
        sendMessageToAllIframes(`${INVENTORY_CLIENT_ID}_STOP_LOADING`, {
          loading: false,
        })
      }
    }

    const resolvePos = () => {
      if (rect.top < containerClientRect.height * 0.05) {
        if (rect.left > (containerClientRect.width - rect.width) * 0.95) {
          return {
            pos: pos1,
            translate: `translate(-30px)`,
            direction: "column",
          }
        }

        return { pos: pos2, translate: `translate(5px)`, direction: "column" }
      }

      return {
        pos: pos2,
        translate: `translate(-50px, -28px)`,
        direction: "row",
      }
    }

    const {
      pos: [x, y],
      translate,
      direction,
    } = resolvePos()

    // Add key (required)
    // Add class prefix moveable-(required)
    return (
      <ActionableWrapper
        key="actionable-viewer"
        className="moveable-actionable"
        // @ts-ignore
        direction={direction}
        gap="1"
        style={{
          transform: `translate(${x}px, ${y}px) rotate(${rect.rotation}deg) ${translate} `,
        }}
      >
        <button
          onClick={handleUse}
          style={{
            width: "24px",
            height: "24px",
            marginBottom: "4px",
            backgroundColor: "green",
            color: "white",
            border: "none",
            fontSize: "12px",
            lineHeight: "20px",
            textAlign: "center",
            cursor: "pointer",
            borderRadius: "4px",
          }}
        >
          {String.fromCodePoint(0x2713)}
        </button>

        <button
          onClick={handleRemove}
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            cursor: "pointer",
            borderRadius: "4px",
          }}
        >
          &times;
        </button>
      </ActionableWrapper>
    )
  },
} as const

const ActionableWrapper = styled(Flex)`
  position: absolute;
  left: 0px;
  top: 0px;
  will-change: transform;
  transform-origin: 0px 0px;
`

export const DimensionViewable = {
  name: "dimensionViewable",
  props: [],
  events: [],
  render(moveable: MoveableManagerInterface) {
    if (!import.meta.env.DEV) return null

    const { scale } = moveable
    const rect = moveable.getRect()

    const [x, y] = rect.origin

    const { round } = Math
    const { format } = Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 3,
    })

    // Add key (required)
    // Add class prefix moveable-(required)
    return (
      <DimensionViewableWrapper
        key="dimension-viewer"
        className="moveable-dimension"
        $top={rect.height + 20}
        $left={rect.width / 2}
      >
        {round(rect.width)} x {round(rect.height)} ({format(scale[1])}) |{" "}
        {round(rect.left)},{round(rect.top)} ({round(x)},{round(y)}) |{" "}
        {round(rect.rotation)}
      </DimensionViewableWrapper>
    )
  },
} as const

const DimensionViewableWrapper = styled.div<{ $top: number; $left: number }>`
  position: absolute;
  left: ${({ $left }) => $left}px;
  top: ${({ $top }) => $top}px;
  background: #4af;
  border-radius: 2px;
  padding: 2px 4px;
  color: white;
  font-size: 13px;
  white-space: nowrap;
  font-weight: bold;
  will-change: transform;
  transform: translate(-50%, 0px);
`
