import { createSelector } from "@reduxjs/toolkit"
import axios from "axios"
import { SERVER_URL } from "../../env"
import { createAppSlice } from "../utils"

export type AuthStatus = "idle" | "fetching" | "error"

interface AuthSlice {
  status: AuthStatus
  tokens: { [clientId: string]: string }
}

interface GetExtensionJWTParams {
  token: string
  clientId: string
  streamerId: string
}

const initialState: AuthSlice = {
  status: "idle",
  tokens: {},
}

const authSlice = createAppSlice({
  name: "auth",
  initialState,
  reducers: (create) => ({
    fetchExtensionJWT: create.asyncThunk(
      async (args: GetExtensionJWTParams, { signal }) => {
        const { token, clientId, streamerId } = args

        const response = await axios.post<{ newToken: string }>(
          `${SERVER_URL}/api/extensions/validatejwt`,
          { clientId, streamerId },
          { headers: { Authorization: `Bearer ${token}` }, signal }
        )

        // console.log(
        //   `Extension ${clientId} decided to fetch and received:\n ${response.data.newToken}`
        // )

        return response.data.newToken
      },
      {
        pending: (state) => {
          state.status = "fetching"
        },
        rejected: (state) => {
          state.status = "error"
        },
        fulfilled: (state, action) => {
          state.status = "idle"
          state.tokens[action.meta.arg.clientId] = action.payload
        },
        options: {
          condition: (args, { getState }) => {
            const { auth } = getState() as any

            return !auth.tokens[args.clientId]
          },
        },
      }
    ),
  }),
  selectors: {
    getAllExtensionJWT: createSelector(
      (state: AuthSlice) => state.tokens,
      (tokens) => (clientId: string) => tokens[clientId]
    ),
  },
})

export const { fetchExtensionJWT } = authSlice.actions
export const { getAllExtensionJWT } = authSlice.selectors
export default authSlice.reducer
