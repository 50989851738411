import { useDispatch } from "react-redux"
import { SERVER_URL } from "../../../../env"
import { setUserStreamerProfile } from "../../../../store/reducers/userStreamerProfileReducer"
import { useToken } from "../../../contexts/TokenContext"
import { useUserProfile } from "../../../contexts/UserProfileContext"
import ActionButton from "../../../ui/ActionButton"
import { useState } from "react"

function BecomeStreamerButton() {
  const dispatch = useDispatch()

  const { token } = useToken()
  const { profile, setProfile } = useUserProfile()

  const isTwitchLinked = !!profile?.linkedAccounts?.Twitch
  const [errorMessage, setErrorMessage] = useState("")

  const handleBecomeStreamer = async () => {
    try {
      if (!token) return

      dispatch({ type: "START_LOADING" })
      const response = await fetch(`${SERVER_URL}/streamer/join`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to become a streamer")
      }

      const data = await response.json()
      console.log(data)

      setProfile(data.existingUser)
      dispatch(setUserStreamerProfile(data.newStreamer))

      console.log("Successfully became a streamer")
    } catch (error) {
      console.error("Error:", error)
      setErrorMessage("Failed to become a streamer. Please try again later.")
      alert(errorMessage) // Show error message using alert
    } finally {
      dispatch({ type: "STOP_LOADING" })
    }
  }

  return (
    <>
      <ActionButton
        onClick={handleBecomeStreamer}
        disabled={{
          status: !isTwitchLinked,
          content: isTwitchLinked
            ? ""
            : "Currently, only accounts with a Twitch account linked are supported.",
        }}
        style={{
          width: "100%",
          height: "65px",
          fontFamily: "SF",
          fontWeight: "bold",
          fontSize: "1.3rem", // Main text font size
        }}
      >
        <div style={{ textAlign: "center" }}>
          Become a Streamer
          <div style={{ fontWeight: "normal", fontSize: "1rem", marginTop: "5px" }}>*Requires 10 followers</div>
        </div>
      </ActionButton>
    </>
  )
}

export default BecomeStreamerButton
