import invariant from "tiny-invariant"

// Checks that all environment variables are set
invariant(import.meta.env.VITE_SERVER_URL, "VITE_SERVER_URL must be set")

invariant(
  import.meta.env.VITE_WEBSOCKET_SERVER_URL,
  "VITE_WEBSOCKET_SERVER_URL must be set"
)

invariant(import.meta.env.VITE_WEBSOCKET_URL, "VITE_WEBSOCKET_URL must be set")

invariant(import.meta.env.VITE_CDN_URL, "VITE_CDN_URL must be set")

invariant(import.meta.env.VITE_ALERT_SERVER, "VITE_ALERT_SERVER must be set")

invariant(
  import.meta.env.VITE_INVENTORY_SERVER_URL,
  "VITE_INVENTORY_SERVER_URL must be set"
)

invariant(
  import.meta.env.VITE_INVENTORY_CLIENT_ID,
  "VITE_INVENTORY_CLIENT_ID must be set"
)

// TODO: Add version check once all releases builds with version

export const {
  VITE_SERVER_URL: SERVER_URL,
  VITE_WEBSOCKET_SERVER_URL: WEBSOCKET_SERVER_URL,
  VITE_WEBSOCKET_URL: WEBSOCKET_URL,
  VITE_CDN_URL: CDN_URL,
  VITE_VERSION: APP_VERSION,
  VITE_ALERT_SERVER: ALERT_SERVER,
  VITE_INVENTORY_SERVER_URL: INVENTORY_SERVER_URL,
  VITE_INVENTORY_CLIENT_ID: INVENTORY_CLIENT_ID,
} = import.meta.env
