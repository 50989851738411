import axios from "axios"
import { Dispatch } from "redux"
import { SERVER_URL } from "../../../../../../env"

export const fetchMyExtensions = async (
  token: string | null,
  dispatch: Dispatch,
  setExtensions: (extensions: any[]) => void
) => {
  if (!token) return

  dispatch({ type: "START_LOADING" })
  try {
    const response = await axios.get(
      `${SERVER_URL}/streamer/extensions/getextensions`,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    console.log(JSON.stringify(response.data))
    setExtensions(response.data)
  } catch (error) {
    console.error("Error fetching my extensions:", error)
  } finally {
    dispatch({ type: "STOP_LOADING" })
  }
}
