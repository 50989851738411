import { Button, ScrollArea } from "@radix-ui/themes"
import { FaPause } from "react-icons/fa"
import { styled } from "styled-components"
import { useMobileContext } from "../../contexts/MobileContext"
import ChatLine from "../chatmessage/ChatLine"
import ChatNotificationBox from "../ChatNotificationBox"
import useChatScroll from "./ChatScrollLogic"
import useChatWebSocket from "./ChatWebSocketDisplay"

interface ChatDisplayProps {
  width?: string;
  height?: string;
  isEmbedded?: boolean;
}

function resolvePausedMessageText(count: number, max: number) {
  if (count > 0) {
    let message = `${Math.min(max, count)}`
    message += `${count > max ? "+" : ""}`
    message += ` message${count > 1 ? "s" : ""} paused`

    return message
  }

  return "Chat Paused"
}

function ChatDisplay({
  isEmbedded = false, // not transparent by default
}: ChatDisplayProps) {
  const { isMobile } = useMobileContext()
  const { messages, buffer } = useChatWebSocket()

  const {
    handleScroll,
    scrollToBottom,
    scrollElementRef,
    showScrollToBottomButton,
    pausedMessageCount,
  } = useChatScroll(messages, buffer, {})

  return (
    <ScrollWrapper
      scrollbars="vertical"
      // type="always"
      $mobile={isMobile}
      onScroll={handleScroll}
      ref={scrollElementRef}
    >
      {messages.map((message) => {
        //message holds type + data - and data holds data
        switch (message.type) {
          case "Event":
          case "CustomEvent":
            return (
              <ChatNotificationBox
                key={message.key}
                message={message}
                isEmbedded={isEmbedded}
              />
            )

          case "None":
            return null

          default:
            return (
              <ChatLine
                key={message.key}
                message={message}
                isEmbedded={isEmbedded} // Pass the prop down to control background logic
              />
            )
        }
      })}
      <div />

      {showScrollToBottomButton && (
        <ScrollToBottom>
          <Button
            size="3"
            color="gray"
            variant="surface"
            // onClick={() => virtualizer.scrollToIndex(messages.length - 1)}
            onClick={() => scrollToBottom({ force: true })}
          >
            <FaPause size="15" />

            {resolvePausedMessageText(pausedMessageCount, 99)}
          </Button>
        </ScrollToBottom>
      )}
    </ScrollWrapper>
  )
}

const ScrollWrapper = styled(ScrollArea) <{
  $mobile: boolean;
}>`
  flex-grow: 1;
  padding-top: ${({ $mobile }) => ($mobile ? "0" : "0.5rem")};

  background-color: transparent;
  
  .rt-ScrollAreaScrollbar {
    margin: 0;
    width: 0.375rem;
  }
`;

const ScrollToBottom = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  min-width: max-content;

  button {
    background-color: #212121;
    color: white;
  }
`

export default ChatDisplay
