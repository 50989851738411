import { SERVER_URL } from "../../env";
import { CommandMeta } from "../../providers/CommandProvider";  // Adjust the path accordingly

interface CommandFromBackend {
  name: string;
  description: string;
  parameters: string[];
  platforms: { name: string; permissions: string[] }[];
  example: string;
}

export async function getCommands(): Promise<CommandMeta[]> {
  const response = await fetch(`${SERVER_URL}/global/commands`);  // Adjust URL as needed

  if (!response.ok) {
    throw new Error("Failed to fetch commands.");
  }

  console.log("WE FETCHED");
  
  const data: CommandFromBackend[] = await response.json();

  // Transform the backend data into CommandMeta format
  return data.map<CommandMeta>(({ name, description, parameters, platforms, example }) => ({
    id: name,  // Using command name as ID
    name,
    description,
    platforms,
    example
  }));
}
