import { useEffect, useMemo, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import {
  getFollowedStreamers,
  getFollowedStreamersStatus,
  Streamer,
  StreamerStatus,
  updateFollowedStreamersOrder,
} from "../../api/Sidebar"
import { useToken } from "../contexts/TokenContext"
import { useUserProfile } from "../contexts/UserProfileContext"
import ShimmerLine from "../shimmer/ShimmerLine"
import StreamerBubble from "./Bubble"

function SidebarBubbles() {
  const { token } = useToken()
  const { profile, profileFetched, reorderStreamerBubble } = useUserProfile()

  const [streamers, setStreamers] = useState<Streamer[]>([])
  const [streamerStatuses, setStreamerStatuses] = useState<StreamerStatus[]>([])

  const streamerIds = useMemo(
    () =>
      (profile?.followedStreamers ?? []).map((streamer) => streamer.chatrpg_id),
    [profile?.followedStreamers]
  )

  // TODO: Minimize effect reruns
  useEffect(() => {
    async function _fetchStreamers() {
      if (!profile?.followedStreamers) return

      const data = await getFollowedStreamers(token)

      setStreamers(
        // Since the `/sidebar` endpoint is always unsorted,
        // sort using the order from `profile.followedStreamers`
        data.sort(
          (a, b) =>
            streamerIds.indexOf(a.chatrpg_id) -
            streamerIds.indexOf(b.chatrpg_id)
        )
      )
    }

    _fetchStreamers()
  }, [profile?.followedStreamers, streamerIds, token])

  useEffect(() => {
    async function _getStreamerStatus(token: string | null) {
      if (!profile?.followedStreamers) return

      const statuses = await getFollowedStreamersStatus(token)
      // Create a new array with new object references to force update
      setStreamerStatuses(statuses.map((status) => ({ ...status })))
    }

    // Run immediately after mounting
    _getStreamerStatus(token)

    const interval = setInterval(() => _getStreamerStatus(token), 300_000)
    return () => clearInterval(interval)
  }, [profile?.followedStreamers, token])

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (!profile?.followedStreamers) return

      // Ensure we are using the latest streamerIds
      const latestStreamerIds = streamers.map((s) => s.chatrpg_id)
      console.log("Saving new order to server:", latestStreamerIds)
      await updateFollowedStreamersOrder(token, latestStreamerIds)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [token, streamers, profile?.followedStreamers])

  if (token && !profileFetched) {
    return (
      <>
        <ShimmerLine />
        <ShimmerLine />
        <ShimmerLine />
      </>
    )
  }

  if (profile && profile.followedStreamers.length > 0) {
    return (
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          // TODO: There's something wrong about this setup :/
          reorderStreamerBubble(source.index, destination?.index ?? 0)
          setStreamers((prev) => {
            const followedStreamers = [...prev]
            const [streamer] = followedStreamers.splice(source.index, 1)
            followedStreamers.splice(destination?.index ?? 0, 0, streamer)
            console.log("New order of streamers after drag:", followedStreamers)

            const newStreamerIds = followedStreamers.map((s) => s.chatrpg_id)
            console.log("New streamerIds after drag:", newStreamerIds)
            return followedStreamers
          })
        }}
      >
        <Droppable droppableId="bubbles">
          {({ droppableProps, ...droppableProvided }, snapshot) => (
            <div {...droppableProps} ref={droppableProvided.innerRef}>
              {streamers.map((streamer, index) => (
                <Draggable
                  key={streamer.chatrpg_username}
                  draggableId={streamer.chatrpg_username}
                  index={index}
                >
                  {(
                    { draggableProps, dragHandleProps, ...draggableProvided },
                    snapshot,
                    rubric
                  ) => (
                    <StreamerBubble
                      {...draggableProps}
                      {...dragHandleProps}
                      ref={draggableProvided.innerRef}
                      streamer={streamer}
                      status={streamerStatuses.find(
                        ({ chatrpg_id }) => chatrpg_id === streamer.chatrpg_id
                      )}
                    />
                  )}
                </Draggable>
              ))}

              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return null
}

export default SidebarBubbles
