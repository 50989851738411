import { Box, Flex } from "@radix-ui/themes"
import { useSelector } from "react-redux"
import { RootState } from "../../../store"
import { getAllExtensionJWT } from "../../../store/slices/Auth"
import { useStreamerProfile } from "../../contexts/StreamerProfileContext"
import { useToken } from "../../contexts/TokenContext"
import ExtensionPlaceholder from "../../extensions/ExtensionPlaceholder"

import "./ChatBottomBar.css"

interface BottomBarPointsOnlyProps {
  extensionName: string
}

function BottomBarPointsOnly({ extensionName }: BottomBarPointsOnlyProps) {
  const { token } = useToken()
  const { streamerProfile } = useStreamerProfile()
  const isEditMode = useSelector((state: RootState) => state.editMode) // Access the editMode state

  const getExtensionJWT = useSelector(getAllExtensionJWT)

  const streamerPointsExtension = streamerProfile.extensions.find(
    ({ type, enabled }) => type === "Points" && enabled
  )

  const jwtToken = streamerPointsExtension
    ? getExtensionJWT(streamerPointsExtension.clientId)
    : null

  const shouldShowPoints = token && jwtToken && streamerPointsExtension

  return (
    <Flex justify="between" align="center" pl="1" pr="2" pb="2" pt="2">
      <div style={{ flex: 1 }}>
        {shouldShowPoints && streamerProfile ? (
          <Box maxHeight="2rem" pl="1" asChild>
            <iframe
              src={`${streamerPointsExtension.buildUrl}?token=${getExtensionJWT(
                streamerPointsExtension.clientId
              )}`}
              className="embeddedProject"
              title="Embedded Page"
            />
          </Box>
        ) : null}

        {isEditMode && <ExtensionPlaceholder className="defaultSize" />}
      </div>

      <Box
        as="span"
        ml="auto"
        pl="2"
        style={{
          flex: 2,
          textAlign: "right",
          color: "var(--chatrpg-logo-color)",
          fontWeight: "bold",
        }}
      >
        {extensionName}
      </Box>
    </Flex>
  )
}

export default BottomBarPointsOnly
