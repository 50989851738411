import { KeyboardEvent, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import {
  ActiveUserSearchResult,
  useActiveUsers,
} from "../../../../providers/ActiveUserProvider"
import {
  CommandSearchResult,
  useCommands,
} from "../../../../providers/CommandProvider"
import {
  EmoteSearchResult,
  useEmotes,
} from "../../../../providers/EmoteProvider"
import { RootState } from "../../../../store"
import { setChatMessage } from "../../../../store/reducers/inputChatReducer"
import AuthModule from "../../../auth/login/AuthModule"
import { useAuthModule } from "../../../auth/login/UseAuthModule"
import { useToken } from "../../../contexts/TokenContext"
import { useWebSocket } from "../../../contexts/WebSocketContext"
import {
  Popover,
  PopoverAnchor,
  PopoverTrigger,
} from "../../../ui/Popover.styled"
import { useSendMessage } from "../../chatHelpers/useSendMessage"
import EmotePopover from "../../EmotePopover"
import TextAreaAutoSize from "./auto-resize-textfield"
import InputMessage from "./helper/InputMessage"
import useCharacterLimit from "./hooks/useInputWithValidation"

// TODO: Do we really need the redux calls?
function ResizableInputForm() {
  const dispatch = useDispatch()

  const { token } = useToken()
  const { status } = useWebSocket()
  const { isAuthModuleOpen, closeAuthModule, handleLoginButtonClick } =
    useAuthModule()
  const sendMessage = useSendMessage()
  const chatMessage = useSelector<RootState, string>(
    (state) => state.chatInput.chatMessage
  )

  const { inputValue, isLimitReached, isConsecutiveLimitReached } =
    useCharacterLimit(chatMessage)

  const { search: searchEmotes } = useEmotes()
  const { search: searchCommands } = useCommands() // Correct search function for commands
  const { search: searchUsers } = useActiveUsers() // Get the active user search function

  const [searchedEmotes, setSearchedEmotes] = useState<EmoteSearchResult[]>([])
  const [searchedCommands, setSearchedCommands] = useState<
    CommandSearchResult[]
  >([]) // Assuming CommandSearchResult type exists
  const [searchedUsers, setSearchedUsers] = useState<ActiveUserSearchResult[]>(
    []
  ) // Add state for users

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!token) {
      handleLoginButtonClick() // Open the auth module if there's no token
      return // Prevent further execution
    }

    const newValue = event.target.value
    const isDeleting = inputValue.length > newValue.length

    if (!(isLimitReached || isConsecutiveLimitReached) || isDeleting) {
      dispatch(setChatMessage(newValue))

      // Only query if:
      // 1) has at least one space before it or is start of message
      // 2) thing starts w/ `:` of course
      // Also, only query end of string
      const emoteMatch = newValue.match(/:(\w+)$/)
      const commandMatch = newValue.match(/\/(\w+)$/)
      const userMatch = newValue.match(/@(\w+)$/) // Detect @username
      console.log("Emote match:", emoteMatch) // Debugging log
      console.log("Command match:", commandMatch) // Debugging log
      console.log("User match:", userMatch) // Debugging log for user match

      if (emoteMatch) {
        setSearchedEmotes(searchEmotes(emoteMatch[1]))
        setSearchedCommands([])
        setSearchedUsers([])
      } else if (commandMatch) {
        setSearchedCommands(searchCommands(commandMatch[1]))
        setSearchedEmotes([])
        setSearchedUsers([])
      } else if (userMatch) {
        setSearchedUsers(searchUsers(userMatch[1])) // Perform user search
        setSearchedEmotes([])
        setSearchedCommands([])
      } else {
        setSearchedEmotes([])
        setSearchedCommands([])
        setSearchedUsers([])
      }
    }
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      sendMessage()
      dispatch(setChatMessage("")) // Clear Redux store
    }

    if (event.key === "Tab") {
      event.preventDefault()

      const emoteMatch = inputValue.match(/:(\w+)$/)
      const commandMatch = inputValue.match(/\/(\w+)$/)
      const userMatch = inputValue.match(/@(\w+)$/) // Detect @username

      if (emoteMatch && searchedEmotes.length > 0) {
        // Auto-complete with the first emote suggestion and remove ':'
        const selectedEmote = searchedEmotes[0]
        const newMessage = inputValue.replace(/:\w+$/, `${selectedEmote.name} `) // Remove the `:`
        dispatch(setChatMessage(newMessage))
        setSearchedEmotes([]) // Clear after selection
      } else if (commandMatch && searchedCommands.length > 0) {
        // Auto-complete with the first command suggestion
        const selectedCommand = searchedCommands[0]
        const newMessage = inputValue.replace(
          /\/\w+$/,
          `/${selectedCommand.name} `
        )
        dispatch(setChatMessage(newMessage))
        setSearchedCommands([]) // Clear after selection
      } else if (userMatch && searchedUsers.length > 0) {
        const selectedUser = searchedUsers[0]
        const newMessage = inputValue.replace(/@\w+$/, `@${selectedUser.name} `)
        dispatch(setChatMessage(newMessage))
        setSearchedUsers([]) // Clear after selection
      }
    }
  }

  const inputRef = useRef<HTMLTextAreaElement>(null)

  return (
    <Popover open={!!chatMessage} onOpenChange={() => {}}>
      {isAuthModuleOpen ? <AuthModule onClose={closeAuthModule} /> : null}

      <EmotePopover
        emotes={searchedEmotes}
        commands={searchedCommands}
        activeUsers={searchedUsers}
        message={chatMessage}
        onClose={() => {
          setSearchedEmotes([])
          setSearchedCommands([])
          setSearchedUsers([])
          inputRef.current?.focus()
        }}
      />

      <PopoverAnchor asChild>
        <Wrapper>
          <PopoverTrigger asChild />

          <InputMessage
            visible={status === "connecting"}
            message="Connecting to chat..."
          />
          <InputMessage
            warning
            visible={isLimitReached}
            message="Reached Character Limit of 500"
          />
          <InputMessage
            warning
            visible={isConsecutiveLimitReached}
            message="Please do not repeat characters"
          />

          <TextArea
            ref={inputRef}
            value={chatMessage}
            placeholder="Send a message"
            minRows={1}
            maxRows={4}
            className="global-focus-style"
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
          />
        </Wrapper>
      </PopoverAnchor>
    </Popover>
  )
}

const Wrapper = styled.form`
  position: relative;
`

const TextArea = styled(TextAreaAutoSize)`
  /* font-family: "SF", sans-serif; */
  width: 100%;
  padding: 10px 10px 10px 42px; /* Left padding includes space for the icon */
  border: none;
  border-radius: 0.25rem;
  background-color: transparent; /* Let the container's background show */
  color: #ececec;
  resize: none;
  overflow: hidden;
  box-shadow: none; /* Remove box-shadow to avoid doubling */
`

export default ResizableInputForm
