import { Avatar, Box, DropdownMenu, Skeleton, Text } from "@radix-ui/themes"
import { APP_VERSION } from "env"
import { IoLogOutOutline, IoSettingsOutline } from "react-icons/io5"
import { useDispatch } from "react-redux"
import { toggleSettings } from "store/reducers/settingsReducer"
import { styled } from "styled-components"
import { useToken } from "../contexts/TokenContext"
import { useUserProfile } from "../contexts/UserProfileContext"
import LoginButtonWithAuth from "../ui/button/LoginButton"

const routes = [
  { label: "Privacy Policy", href: "/privacy-policy" },
  { label: "Terms of Service", href: "/terms-of-service" },
]

function RightSection() {
  const dispatch = useDispatch()

  const { token, clearToken } = useToken()
  const { profile, profileFetched, setProfile } = useUserProfile()
  // const { streamerProfile } = useStreamerProfile()
  // const getExtensionJWT = useSelector(getAllExtensionJWT)

  // const streamerPointsExtension = streamerProfile.extensions.find(
  //   ({ type, enabled }) => type === "Points" && enabled
  // )

  // const jwtToken = streamerPointsExtension ? getExtensionJWT(streamerPointsExtension.clientId) : null;
  // const shouldShowPoints = token && jwtToken && streamerPointsExtension;

  // // Add the JSX to display the iframe within the existing component layout
  // if (shouldShowPoints && streamerProfile) {
  //   console.log("Rendering iframe for streamer points extension");
  // }

  const handleLogout = () => {
    clearToken()
    setProfile(null)
  }

  if (profile) {
    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <UserAvatarButton
            src={profile.chatrpg_profilePicture}
            alt={profile.chatrpg_username}
            fallback={profile.chatrpg_username[0]}
            size="2"
            radius="full"
            mr="3"
          />
        </DropdownMenu.Trigger>

        <DropdownContent variant="soft" color="gray" align="end" sideOffset={5}>
          <DropdownMenu.Item
            onSelect={(e) => e.preventDefault()}
            // <Flex justify="center" align="center" gap="4" px="3" py="2" mb="-1">
            style={{
              height: "auto",
              gap: "var(--space-4)",
              paddingTop: "var(--space-2)",
              paddingBottom: "var(--space-2)",
              marginBottom: "calc(-1* var(--space-1))",
            }}
          >
            <Avatar
              src={profile.chatrpg_profilePicture}
              alt={profile.chatrpg_username}
              fallback={profile.chatrpg_username[0]}
              size="4"
              radius="full"
            />
            <Text size="3" weight="bold">
              {profile!.chatrpg_username}
            </Text>
          </DropdownMenu.Item>

          <DropdownMenu.Separator />

          <DropdownMenu.Item onSelect={() => dispatch(toggleSettings())}>
            <IoSettingsOutline size={24} color="var(--chatrpg-logo-color)" />
            Settings
          </DropdownMenu.Item>

          <DropdownMenu.Item onSelect={handleLogout}>
            <IoLogOutOutline size={25} color="var(--chatrpg-logo-color)" />
            Logout
          </DropdownMenu.Item>

          <DropdownMenu.Separator />

          {routes.map((route) => (
            <DropdownMenu.Item key={route.label}>
              <a href={route.href} target="_blank" rel="noopener noreferrer">
                {route.label}
              </a>
            </DropdownMenu.Item>
          ))}

          {APP_VERSION && (
            <>
              <DropdownMenu.Separator />

              <DropdownMenu.Item
                disabled
                style={{ margin: "calc(-1* var(--space-2)) 0" }}
                // asChild
              >
                <Box my="-2">
                  <Text size="1">ChatRPG Build: {APP_VERSION}</Text>
                </Box>
              </DropdownMenu.Item>
            </>
          )}
        </DropdownContent>
      </DropdownMenu.Root>
    )
  }

  if (token && !profileFetched) {
    return (
      <Skeleton
        height="2rem"
        width="2rem"
        mr="3"
        style={{ borderRadius: "100%" }}
      />
    )
  }

  return <LoginButtonWithAuth />
}

const UserAvatarButton = styled(Avatar)`
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;

  &:hover {
    border: 2px solid #f8b426;
  }
`

const DropdownContent = styled(DropdownMenu.Content)`
  /* min-width: 13rem; */
  background-color: #18181b;

  .rt-DropdownMenuViewport {
    gap: 0.25rem;
  }

  .rt-DropdownMenuItem:not([data-disabled]):not(:first-child) {
    cursor: pointer;
  }
`

export default RightSection
