import axios from "axios"
import { Dispatch } from "redux"
import { SERVER_URL } from "../../../../../../env"

export const fetchPopularExtensions = async (
  token: string | null,
  dispatch: Dispatch,
  setExtensions: (extensions?: any[]) => void
) => {
  if (!token) return

  try {
    dispatch({ type: "START_LOADING" })

    const response = await axios.get(
      `${SERVER_URL}/extensions/unownedpopularextensions`,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    const fetchedExtensions = response.data.map((ext: any) => ({
      title: ext.extensions[0].extensionName,
      author: ext.chatrpg_username,
      description: ext.extensions[0].description,
      clientId: ext.extensions[0].clientId,
    }))

    setExtensions(fetchedExtensions)
  } catch (error) {
    console.error("Failed to fetch popular extensions:", error)
  } finally {
    dispatch({ type: "STOP_LOADING" })
  }
}
